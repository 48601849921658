<template>
  <div class="container-fluid mt-5">
    <div class="row align-items-start mt-5">
      <div class="lala mt-5">
        <Experience  />
      </div>
    </div>
  </div>
</template>

<script>
import Experience from '../components/Experience.vue'
export default {
  components: {
    Experience
  }
}
</script>
<style>

</style>